<template />

<script>
export default {
  name: 'linkedinAuth',

  computed: {
    code() {
      return this.$route.query.code;
    },
  },
  async created() {
    await this.authLinkedin();
  },
  methods: {
    async authLinkedin() {

      await this.$store.dispatch('linkedinAuth', {
        code: this.code,
        linkedin: true
      });
      
      this.$router.push({ path: this.$route.query.state != null && this.$route.query.state !== "null" ? `/${this.$route.query.state}/welcome` : "/" }); // AQUÍ ES DONDE VA LA URL DONDE LO VAS A REDIRECCIONAR
      return '';
    },
  },
};
</script>
